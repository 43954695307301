import { Box, Container, Flex, Link, Stack, Center } from "@chakra-ui/react";
import { Instagram } from "react-feather";
import styles from "./Footer.module.css";
import NextLink from "next/link";
import Image from "next/image";

import bannerWhite from "public/images/banner-white.png";
import XLogo from "public/images/x-logo.svg";

export default function () {
  return (
    <footer className={styles.Footer}>
      <Box bg="secondary.900" color="white">
        <Container pt={12} pb={12} maxW="container.lg">
          <Flex
            justifyContent={"space-evenly"}
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "center", md: "center" }}
            marginX={{ base: "8", md: 0 }}
          >
            <Box mb={{ base: 8, md: 0 }}>
              <Image src={bannerWhite} alt="logo" width={200} height={75} />
              <Center mt={2}>
                <small>© Tête en l'air {new Date().getFullYear()}</small>
              </Center>
            </Box>
            <Box>
              <ul>
                <li>
                  <NextLink href={"/rejoindre"} passHref>
                    <Link>Rejoindre l'équipe</Link>
                  </NextLink>
                </li>
                <li>
                  <NextLink href={"/faq"} passHref>
                    <Link>FAQ</Link>
                  </NextLink>
                </li>
              </ul>
              <ul>
                <li>
                  <NextLink href={"/legal"} passHref>
                    <Link>Mentions légales</Link>
                  </NextLink>
                </li>
                <li>
                  <NextLink href={"/cgv"} passHref>
                    <Link>Conditions générales de vente</Link>
                  </NextLink>
                </li>
                <li>
                  <NextLink href={"/cookies"} passHref>
                    <Link>Cookies</Link>
                  </NextLink>
                </li>
                <li>
                  <NextLink href={"/data"} passHref>
                    <Link>Charte des données personnelles</Link>
                  </NextLink>
                </li>
              </ul>
            </Box>
            <Box mt={{ base: 8, md: 0 }}>
              <Center>
                <Stack direction="row">
                  <Link
                    color={"white"}
                    p={2}
                    isExternal
                    href="https://twitter.com/latetenlair"
                    title="Twitter"
                  >
                    <Image src={XLogo} alt="Twitter" width={23} height={23} />
                  </Link>
                  <Link
                    color={"white"}
                    p={2}
                    isExternal
                    href="https://www.instagram.com/tete.en.lair.aviation/"
                    title="Instagram"
                  >
                    <Instagram alt="Instagram" />
                  </Link>
                </Stack>
              </Center>
            </Box>
          </Flex>
        </Container>
      </Box>
    </footer>
  );
}
