import { chakraComponents } from "chakra-react-select";
import { Text } from "@chakra-ui/react";

export const customAirlineSelectComponents = {
  MenuList: ({ children, ...props }) => (
    <chakraComponents.MenuList {...props}>
      {Array.isArray(children)
        ? children.slice(0, props.selectProps?.maxOptions)
        : children}
    </chakraComponents.MenuList>
  ),
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      {props.data.icon} <Text>{children}</Text>
      <Text ml={1} as="samp">
        <small>({props.data.value})</small>
      </Text>
    </chakraComponents.Option>
  ),
};
