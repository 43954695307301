import { supabase } from "utils/supabaseClient";
import Image from "next/image";
import {
  Container,
  Flex,
  Button,
  Heading,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text,
  Stack,
  Textarea,
  useToast,
  Box,
  Checkbox,
  Link,
  InputGroup,
  InputLeftAddon,
  Switch,
  FormHelperText,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import NextLink from "next/link";
import Router from "next/router";
import { useState, useRef, useMemo } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";

import styles from "styles/Home.module.css";
import Navigation from "components/Navigation";
import Footer from "components/Footer";
import { customAirlineSelectComponents } from "components/shared/customAirlineSelectComponents";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-cards";

import photo1 from "public/images/home/J_1.jpg";
import photo2 from "public/images/home/J_2.jpg";
import photo3 from "public/images/home/J_3.jpg";
import photo4 from "public/images/home/J_4.jpg";
import photo5 from "public/images/home/J_5.jpg";
import photo6 from "public/images/home/J_6.jpg";
import photo7 from "public/images/home/J_7.jpg";
import photo8 from "public/images/home/J_8.jpg";
import photo9 from "public/images/home/J_9.jpg";
import photo10 from "public/images/home/J_10.jpg";
import photo11 from "public/images/home/J_11.jpg";
import photo12 from "public/images/home/J_12.jpg";
import photo13 from "public/images/home/J_13.jpg";
import photo14 from "public/images/home/J_14.jpg";
import photo15 from "public/images/home/J_15.jpg";
import photo16 from "public/images/home/P_1.png";
import photo17 from "public/images/home/P_2.png";
import photo18 from "public/images/home/P_3.png";
import photo19 from "public/images/home/P_4.png";
import photo20 from "public/images/home/P_5.png";
import photo21 from "public/images/home/P_6.png";
import photo22 from "public/images/home/P_7.png";
import photo23 from "public/images/home/P_8.png";
import photo24 from "public/images/home/P_9.png";
import photo25 from "public/images/home/P_10.png";
import photo26 from "public/images/home/P_11.png";
import photo27 from "public/images/home/P_12.png";
import photo28 from "public/images/home/P_13.png";
import photo29 from "public/images/home/P_14.png";

const images = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
  photo15,
  photo16,
  photo17,
  photo18,
  photo19,
  photo20,
  photo21,
  photo22,
  photo23,
  photo24,
  photo25,
  photo26,
  photo27,
  photo28,
  photo29,
];

import airlines from "../public/json/airlines.json";

export default function Home({ airports }) {
  const toast = useToast();

  const [formErrors, setFormErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPrivateFlight, setPrivateFlight] = useState(false);
  const [airlineOption, setAirlineOption] = useState(null);
  const [commentSize, setCommentSize] = useState(0);
  const commentMaxSize = 200;

  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  const handleOrderForm = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setFormErrors([]);

    const data = {
      client_name: event.target.name.value,
      client_email: event.target.email.value,
      airport_id: event.target.airport.value,
      flight_number: event.target.flightnumber.value,
      flight_date: event.target.flightdate.value,
      comment: event.target.comment.value,
    };

    let errors = [];
    Object.entries(data).map(([key, value]) => {
      if (!value && key !== "comment" && key !== "client_name") {
        errors = [...errors, key];
      }
    });

    // airline
    if (!airlineOption && !isPrivateFlight) {
      errors.push("airline");
    }

    // verify captcha
    if (!token) {
      errors.push("captcha");
    }

    if (errors.length > 0) {
      setIsLoading(false);
      return setFormErrors(errors);
    }

    // Prepending flight number with airline IATA code…
    data.flight_number = (airlineOption?.value ?? "") + data.flight_number;

    const res = await fetch("/api/orders", {
      body: JSON.stringify({ ...data, captcha: token }),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    });

    if (res.status !== 200) {
      setIsLoading(false);
      return toast({
        title: "Une erreur est survenue, réessayez plus tard…",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setFormErrors([]);
    setIsLoading(false);
    Router.push("/confirmation");
  };

  const minimumDate = useMemo(() => new Date().toISOString().split("T")[0], []);

  return (
    <>
      <section className={styles.HomeHero}>
        <Navigation />
        <Container
          maxW="container.lg"
          className={styles.HeroContainer}
          alignItems={"center"}
        >
          <Flex mt={8} justify={"space-evenly"} p={12} h={"100%"} wrap={"wrap"}>
            <Box flex={1} minW={{ base: 0, md: "400px" }}>
              <Center flexDirection={"column"} alignItems={"start"}>
                <Heading
                  size={"2xl"}
                  color={"white"}
                  fontWeight={"bold"}
                  mb={2}
                >
                  Obtenez un souvenir de votre vol
                </Heading>
                <Heading size={"md"} color={"white"} fontWeight={"light"}>
                  Vous allez prendre l'avion et souhaitez obtenir un petit
                  souvenir de votre vol ?
                </Heading>
                <Heading size={"md"} color={"white"} fontWeight={"light"}>
                  Notre communauté de passionnés vous propose de recevoir un
                  petit cliché de votre décollage / atterrissage.
                </Heading>
                <NextLink href="#cliche">
                  <Button
                    mt={6}
                    size={"lg"}
                    colorScheme={"brand"}
                    _hover={{
                      color: "white",
                      bgColor: "brand.main",
                    }}
                  >
                    Recevez votre cliché
                  </Button>
                </NextLink>
                <NextLink href="/rejoindre">
                  <Button
                    mt={2}
                    variant={"outline"}
                    colorScheme={"gray"}
                    color="white"
                    _hover={{
                      bg: "white",
                      color: "secondary.main",
                    }}
                  >
                    Rejoindre l'équipe
                  </Button>
                </NextLink>
              </Center>
            </Box>
            <Box flex={1} pl={4} mt={8} ml={32}>
              <Swiper
                spaceBetween={50}
                slidesPerView={3}
                autoplay
                loop
                effect="cards"
                modules={[EffectCards, Autoplay]}
                className={styles.SwiperContainer}
              >
                {images
                  .sort((a, b) => 0.5 - Math.random())
                  .map((item, index) => (
                    <SwiperSlide key={index} className={styles.slide}>
                      <Box
                        bg="white"
                        p={2}
                        pb={12}
                        h={"100%"}
                        w={"100%"}
                        borderRadius={8}
                        boxShadow={"2xl"}
                      >
                        <Box
                          bg={"black"}
                          height={"100%"}
                          borderRadius={6}
                          // style={{
                          //   backgroundImage: `url(/images/backgrounds/login${item}.jpg)`,
                          //   backgroundSize: "cover",
                          //   backgroundPosition: "center",
                          // }}
                        >
                          <Image
                            // src={`/images/backgrounds/login${item}.jpg`}
                            src={item}
                            priority={true}
                            alt="Illustration d'avion"
                            placeholder="blur"
                            // objectFit="cover"
                            height="270"
                            width="240"
                            // layout="fill"
                          />
                        </Box>
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Box>
            {/* <Image src={"/images/logo.png"} width={300} height={300} flex={1} /> */}
          </Flex>
        </Container>
      </section>
      <div className={styles.CloudDivider}></div>
      <Container maxW="container.lg" mt={8} mb={40}>
        <Heading size="lg" id="cliche" pt="8">
          Commander mon cliché
        </Heading>
        <Heading size={"md"} mt={4} color="gray.600" mb={4}>
          Renseignez quelques informations et on s'occupe de tout !
        </Heading>
        <Text size={"md"} mb={10}>
          Le tarif d'une prestation est de{" "}
          <a className="link" href="/faq#prix" target="_blank">
            10 €
          </a>
          .
        </Text>
        <form onSubmit={handleOrderForm}>
          <Stack maxWidth={{ sm: "100%", md: 320 }} spacing={4}>
            <FormControl isRequired>
              <FormLabel htmlFor="email">Adresse e-mail </FormLabel>
              <Input
                id="email"
                name="email"
                required
                type="email"
                variant="filled"
                placeholder="email@exemple.com"
                colorScheme="secondary"
                autoComplete="email"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="name">
                Prénom et Nom (ou pseudo){" "}
                <small>
                  <Text color="gray.500" display="inline">
                    (facultatif)
                  </Text>
                </small>
              </FormLabel>
              <Input
                id="name"
                name="name"
                type="text"
                variant="filled"
                placeholder="Louis Blériot"
                colorScheme="secondary"
                autoComplete="name"
              />
            </FormControl>
          </Stack>
          <Stack spacing={4} mt={8}>
            <FormControl maxWidth={{ sm: "100%", md: 320 }} isRequired>
              <FormLabel htmlFor="flightdate">Date du vol</FormLabel>
              <Input
                id="flightdate"
                name="flightdate"
                required
                type="date"
                variant="filled"
                min={minimumDate}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                colorScheme="secondary"
              />
              <small>
                <Text lineHeight={1.4} mt={2}>
                  Indiquez la date de la photo ; par exemple, pour une photo de
                  votre atterrissage après un vol de nuit, saisir la date à
                  l'arrivée.
                </Text>
              </small>
            </FormControl>
            <FormControl
              maxWidth={{ sm: "100%", md: 420 }}
              isInvalid={formErrors.includes("airport_id")}
              isRequired
            >
              <FormLabel htmlFor="airport">
                Aéroport de départ ou d'arrivée
              </FormLabel>
              <Select
                id="airport"
                name="airport"
                placeholder="Choisir un aéroport…"
                required
                variant="filled"
                colorScheme="secondary"
                selectedOptionColor="brand"
                options={airports.map((airport) => ({
                  value: airport.id,
                  label: `${airport.code_IATA} - ${airport.label}`,
                }))}
              />
              {formErrors.includes("airport_id") && (
                <FormErrorMessage>
                  Vous devez sélectionner un aéroport.
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              maxWidth={{ sm: "100%", md: 420 }}
              isInvalid={formErrors.includes("airline")}
              isRequired={!isPrivateFlight}
              isDisabled={isPrivateFlight}
            >
              <FormLabel htmlFor="flightnumber">Compagnie</FormLabel>
              <Select
                id="airline"
                name="airline"
                value={airlineOption}
                onChange={(option) => setAirlineOption(option)}
                placeholder="Choisir une compagnie…"
                isInvalid={formErrors.includes("airline")}
                required={!isPrivateFlight}
                colorScheme="secondary"
                selectedOptionColor="brand"
                options={airlines.map((airline) => ({
                  value: airline.iata,
                  label: airline.name,
                  icon: (
                    <img
                      src={airline.logo}
                      width={24}
                      height={24}
                      style={{ marginRight: 8 }}
                      alt={`Logo de la compagnie ${airline.name}`}
                    />
                  ),
                }))}
                maxOptions={5}
                components={customAirlineSelectComponents}
                isClearable
              />
              {formErrors.includes("airline") && (
                <FormErrorMessage>
                  Vous devez sélectionner une compagnie.
                </FormErrorMessage>
              )}
              <FormControl
                display="flex"
                alignItems="center"
                mt={2}
                isDisabled={airlineOption !== null}
              >
                <Switch
                  size="sm"
                  id="privateFlight"
                  checked={isPrivateFlight}
                  onChange={(evt) => {
                    setAirlineOption(null);
                    setPrivateFlight(evt.currentTarget.checked);
                  }}
                />
                <FormLabel htmlFor="privateFlight" ml={2} mb={0}>
                  <Text fontSize="sm" color="gray.500">
                    Il s'agit d'un vol privé ou ma compagnie n'est pas listée.
                  </Text>
                </FormLabel>
              </FormControl>
            </FormControl>
            <FormControl
              maxWidth={{ sm: "100%", md: 320 }}
              isDisabled={!airlineOption && !isPrivateFlight}
              isRequired
            >
              <FormLabel htmlFor="flightnumber">Numéro de vol</FormLabel>
              <InputGroup>
                {!isPrivateFlight && airlineOption !== null && (
                  <InputLeftAddon>{airlineOption?.value ?? "—"}</InputLeftAddon>
                )}
                <Input
                  id="flightnumber"
                  name="flightnumber"
                  required
                  type="number"
                  placeholder="420"
                  colorScheme="secondary"
                />
              </InputGroup>
            </FormControl>
          </Stack>
          <FormControl mt={8} maxWidth={{ sm: "100%", md: 420 }}>
            <FormLabel htmlFor="comment">
              Commentaire{" "}
              <small>
                <Text
                  color={commentSize === commentMaxSize ? "red" : "gray.500"}
                  display="inline"
                >
                  ({commentSize}/{commentMaxSize})
                </Text>
              </small>
            </FormLabel>
            <Textarea
              id="comment"
              name="comment"
              variant="filled"
              placeholder="Informations complémentaires…"
              colorScheme="secondary"
              maxLength={commentMaxSize}
              onChange={(e) => setCommentSize(e.target.value.length)}
            />
          </FormControl>
          <FormControl mt={8} as="fieldset">
            <Checkbox
              colorScheme={"brand"}
              onChange={(e) => setIsDisabled(!e.target.checked)}
            >
              J'accepte les{" "}
              <Link href="/cgv" color="brand.500" isExternal>
                Conditions Générales de Vente (CGV)
              </Link>
              .
            </Checkbox>
          </FormControl>
          <FormControl
            mt={8}
            isRequired
            isInvalid={formErrors.includes("captcha")}
          >
            <FormLabel htmlFor="captcha" hidden>
              Je ne suis pas un robot
            </FormLabel>
            <HCaptcha
              id="captcha"
              sitekey="a9150815-2f59-44e9-a194-ff4af0d9c35b"
              onVerify={setToken}
              ref={captchaRef}
            />
            {formErrors.includes("captcha") && (
              <FormErrorMessage>
                Vous devez valider le captcha.
              </FormErrorMessage>
            )}
          </FormControl>
          <Button
            type="submit"
            colorScheme="brand"
            isLoading={isLoading}
            disabled={isDisabled}
            mt={8}
          >
            Demander mon cliché
          </Button>
          <Stack maxW="container.sm" spacing={4} mt={12}>
            <Text fontSize="xs">
              💰&nbsp;Le paiement du cliché s'effectue à réception uniquement,
              sous réserve qu'un photographe se soit positionné sur votre
              commande. Consultez nos{" "}
              <a className="link" href="/cgv" target="_blank">
                CGV
              </a>{" "}
              et notre{" "}
              <a className="link" href="/faq" target="_blank">
                FAQ
              </a>{" "}
              pour en savoir plus sur les paiements et les conditions
              tarifaires.
            </Text>
            <Text color="gray.600" fontSize="xs">
              ℹ️ &nbsp;Tête en l'air reste une communauté de passionnés ; malgré
              tous nos efforts, nous ne pouvons garantir à 100% le traitement de
              votre demande, qui dépend de la disponibilité de nos photographes.
              Notre communauté s'agrandit de jour en jour pour assurer une plus
              grande couverture de vos vols ! ✈️
            </Text>
          </Stack>
        </form>
      </Container>
      <Footer />
    </>
  );
}

export const getServerSideProps = async (context) => {
  const { data: airports } = await supabase
    .from("airports")
    .select(`id, code_IATA, label`)
    .order("code_IATA", { ascending: true });

  return {
    props: { airports },
  };
};
