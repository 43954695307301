import {
  Flex,
  Box,
  Spacer,
  Button,
  Container,
  Link,
  Stack,
} from "@chakra-ui/react";
import styles from "./Navigation.module.css";
import Image from "next/image";
import NextLink from "next/link";
import { Instagram } from "react-feather";

import banner from "public/images/banner.png";
import bannerWhite from "public/images/banner-white.png";
import XLogo from "public/images/x-logo.svg";

export default function Navigation({ transparent, darkImage, ...props }) {
  return (
    <div className={transparent ? "" : styles.Colored}>
      <Container maxW="container.xl" {...props}>
        <Flex
          pt={4}
          pb={4}
          align={"center"}
          // flexWrap="wrap"
          justify={{ base: "space-between", lg: "space-between" }}
        >
          <Box p="2">
            <NextLink href="/" passHref>
              <Link>
                <Image
                  alt="logo"
                  priority={true}
                  src={darkImage ? banner : bannerWhite}
                  placeholder="blur"
                  width={280}
                  height={100}
                  // layout="fixed"
                />
              </Link>
            </NextLink>
          </Box>

          <Stack spacing={"2em"} direction="horizontal">
            <Link
              color={transparent ? "secondary.main" : "white"}
              p={2}
              isExternal
              mr={6}
              href="https://twitter.com/latetenlair"
              display={{ base: "none", lg: "initial" }}
              title="Twitter"
            >
              <Image src={XLogo} alt="Twitter" width={23} height={23} />
            </Link>
            <Link
              color={transparent ? "secondary.main" : "white"}
              p={2}
              isExternal
              mr={4}
              href="https://www.instagram.com/tete.en.lair.aviation/"
              display={{ base: "none", lg: "initial" }}
              title="Instagram"
            >
              <Instagram alt="Instagram" />
            </Link>
            <Link
              color={transparent ? "secondary.main" : "white"}
              p={2}
              mr={4}
              href="/faq"
              display={{ base: "none", lg: "initial" }}
            >
              FAQ
            </Link>
            <Link
              color={transparent ? "secondary.main" : "white"}
              p={2}
              isExternal
              mr={8}
              href="https://shop.tete-en-lair.fr/shop"
              display={{ base: "none", lg: "initial" }}
              title="Boutique"
            >
              Boutique
            </Link>
            <NextLink href="/spotter">
              <Button
                variant="outline"
                color={transparent ? "secondary.main" : "white"}
                _hover={{
                  bgColor: transparent ? "secondary.main" : "white",
                  color: transparent ? "white" : "secondary.main",
                }}
              >
                Espace spotter
              </Button>
            </NextLink>
          </Stack>
        </Flex>
      </Container>
    </div>
  );
}
